import { reactive, ref, watch } from 'vue';
import { apiEgpmTaskPage } from '@/request/egpmBrandApi';
import { useRoute } from "vue-router";

export default () => {
    const $route = useRoute();
    const query = reactive({
        reportConfigId: $route.query.reportId,
        keyword: '',
        date: [],
        taskState: '',// 任务状态:0-初始化,1-执行中,2-已完成,3-失败
        pageNum: 1,
        pageSize: 10
    })
    const total = ref(0);
    const tableData = ref([]);

    // 获取数据
    async function getTableData() {
        const params = { ...query };
        if (params.date?.length === 2) {
            params.startTime = params.date[0];
            params.endTime = params.date[1];
        }
        delete params.date;
        const { data } = await apiEgpmTaskPage(params);
        total.value = data?.total || 0;
        tableData.value = data?.list || [];
    }

    // 分页
    watch(() => query.pageNum, getTableData)

    // 搜索
    function search() {
        query.pageNum = 1;
        getTableData();
    }

    // 重置
    function reset() {
        query.keyword = '';
        query.date = [];
        query.taskState = '';
        query.pageNum = 1;
        getTableData();
    }

    getTableData();

    return { query, total, tableData, reset, search, getTableData }
}